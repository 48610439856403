import { console } from './tools';
import base64Encoder from './base64';

/**
 * @typedef {function} ErrorHandler
 * @param {string} reason String, represents error
 * @param {object|string} [exception] Exception object
 * @return {void}
 */

/**
 * @typedef {function} InfoCallback
 * @return {PageInfo|null}
 */

/**
 * Error handler factory
 *
 * @param {string} baseURL
 * @param {InfoCallback} infoCallback
 * @return {ErrorHandler} Default error handler
 */
export default function errorHandlerFactory(baseURL, infoCallback) {
    function defaultErrorHandler(errorMessage, exceptionInfo) {
        const pageInfo = infoCallback();
        const endpoint = `${baseURL}/err.gif`;
        try {
            const code = errorMessage.split(':')[0];
            const pageId = pageInfo && pageInfo.page_id;
            let details;
            if (exceptionInfo instanceof Error) {
                details = exceptionInfo.stack || exceptionInfo.toString();
            } else {
                details = `${exceptionInfo}`;
            }

            new Image().src = endpoint
                + `?w=${code}`
                + (pageId ? `&p=${pageId}` : '')
                + (details ? `&e=${base64Encoder(details)}` : '');

            console.error(errorMessage, exceptionInfo);
        } catch (_) {} //eslint-disable-line no-empty
    }
    return defaultErrorHandler;
}
