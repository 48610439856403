/* eslint-disable camelcase */
import {console, toSeconds} from './tools';

const par = window.parent;
const perf = par.performance;

/**
 * @typedef {object} ActivityPayload
 * @property {string} category Event category, always "clerk"
 * @property {string} action Event action, always "activity"
 * @property {number} focus_time time spent on the page when it is in focus
 * @property {number} idle_time Inactivity time of the page
 * @property {number} [load_start] start timestamp of page loading
 * @property {number} [dom_loaded] duration of loading the page DOM tree
 * @property {number} [page_loaded] duration of loading the entire page content
 * @property {number} [page_unload] time from the start of the page loading to its unloading
 *
 **/

/**
 * @type ActivityPayload
 * @access private
 **/
const defaultActivity = {
    category: 'clerk',
    action: 'activity',
    focus_time: 0,
    idle_time: 0
};

/**
 * @classdesc Metrics class
 */
export class Metrics {
    constructor() {
        if (!par.performance) {
            if (DEBUG) {
                console.error('E004: Web Performance API unavailable');
            }
            return;
        }
        this.reset();
        this.attachListeners();
        if (DEBUG) {
            console.debug(`CLERK: Page loading begins at ${new Date(this.metrics.load_start)}`);
        }
    }

    /**
     * Return current performance and activity metrics
     *
     * @return {ActivityPayload}
     */
    get activity() {
        return this.metrics;
    }

    /**
     * Resets all collected performance metrics
     *
     * @param {boolean} [fromDate] Use Date as source of start time; instead, use performance API
     * @return {void}
     */
    reset(fromDate=false) {
        this.metrics = Object.assign({}, defaultActivity);
        let fetchStart;
        if (fromDate) {
            fetchStart = +new Date();
        } else {
            fetchStart = perf.timing.fetchStart;
        }
        this.lastFocus = fetchStart;
        this.lastBlur = fetchStart;
        this.fetchStart = fetchStart;
        this.metrics.load_start = fetchStart;
        if (DEBUG) {
            console.debug('CLERK: Metrics initialized', this.metrics);
        }
    }

    /**
     * Attach measuring listeners to parent window
     *
     * @return {void}
     */
    attachListeners() {
        par.addEventListener('load', () => {
            const {timing: {domContentLoadedEventStart, loadEventStart}} = perf;

            this.metrics.dom_loaded = domContentLoadedEventStart - this.fetchStart;
            if (DEBUG) {
                console.debug(`CLERK: DOM loaded took ${toSeconds(this.metrics.dom_loaded)} seconds`);
            }

            this.metrics.page_loaded = loadEventStart - this.fetchStart;
            if (DEBUG) {
                console.debug(`CLERK: Entire page loaded in ${toSeconds(this.metrics.page_loaded)} seconds`);
            }
        });

        par.addEventListener('beforeunload', () => {
            this.metrics.page_unload = +new Date() - this.fetchStart;
            if (DEBUG) {
                console.debug('CLERK: Collected metrics', this.metrics);
            }
        });

        par.addEventListener('focus', () => {
            if (!this.lastBlur) return;

            this.lastFocus = +new Date();
            let idleTime = this.lastFocus - this.lastBlur;
            this.lastBlur = null;

            this.metrics.idle_time += idleTime;

            if (DEBUG) {
                console.debug(`CLERK: ${toSeconds(idleTime)} seconds to idle time`);
            }
        });

        par.addEventListener('blur', () => {
            if (!this.lastFocus) return;

            this.lastBlur = +new Date();
            let focusTime = this.lastBlur - this.lastFocus;
            this.lastFocus = null;

            this.metrics.focus_time += focusTime;

            if (DEBUG) {
                console.debug(`CLERK: ${toSeconds(focusTime)} seconds to active time`);
            }
        });
    }
}
