/* eslint-disable camelcase */

/**
 * @typedef {object.<string, string|boolean>} Features
 * @property {string} screen_vp Viewport size (width × height)
 * @property {string} screen_res Screen resolution (width × height)
 * @property {string} window_size Window size (width × height)
 * @property {string} [java] Java version, if present
 * @property {string} [flash] Flash version, if present
 * @property {boolean} has_beacon Beacon API presence
 * @property {boolean} has_cors CORS presence
 **/

/**
 * Detects various features of user agent
 *
 * @returns {Features}
 */
export default function detect() {
    const result = {};
    const scr = screen;
    result.screen_vp = scr.availWidth + 'x' + scr.availHeight;
    result.screen_res = scr.width + 'x' + scr.height;

    const body = document.body;
    result.window_size = body.clientWidth + 'x' + body.clientHeight;

    const nav = navigator;
    const java = nav.mimeTypes && nav.mimeTypes['application/x-java-applet'];
    if(java) {
        result.java = java.enabledPlugin.name;
    }
    const flashplug = nav.plugins && nav.plugins['Shockwave Flash'];
    if(flashplug) {
        result.flash = flashplug.description;
    }
    result.has_beacon = (typeof nav.sendBeacon) === 'function';
    result.has_cors = 'withCredentials' in new XMLHttpRequest();
    return result;
}
