/**
 * @typedef {function} Sender
 * @param {Array.<ClerkEvent>} eventsList
 * @return {boolean} Success flag
 */
/**
 * @typedef {object.<string, string>} Dimensions
 * @property {string} [project] Project of page
 */

import detectFeatures from './features';
import { Queue } from './queue';
import { Metrics } from './metrics';
import {console, getUrl, cleanObject, isNonEmpty} from './tools';
import transportFactory from './transport';
import errorHandlerFactory from './error';

let validateDimensions;
if (DEBUG) {
    validateDimensions = require('./apeiron').validateDimensions;
}

const win = window;
const doc = win.document;


/**
 * Send page info to subscribers
 *
 * @param {PageInfo} response Page information, received from iframe
 * @access private
 * @static
 */
function sendInfo(response) {
    if (DEBUG) {
        console.debug('CLERK: Send info', response);
    }
    win.postMessage({clerk: {response}}, '*');
}

/**
 * Request new page info from iframe
 *
 * @access private
 * @static
 */
function requestPageInfo() {
    if (DEBUG) {
        console.debug('CLERK: Requested page info from iframe');
    }
    doc.getElementById('clerk_iframe').contentWindow.postMessage({clerk: {request: 'getPageInfo'}}, '*');
}

/**
 * @classdesc Clerk class
 */
export class ClerkStats {
    constructor(config, oldData) {
        if(config === undefined) {
            console.error('E001: No Clerk configuration found');
            return;
        }
        if(!Array.isArray(oldData)) {
            console.error('E002: No data or two Clerks loaded');
            return;
        }
        if(DEBUG) {
            console.debug('CLERK: Initialized', VERSION);
            console.debug(config);
            if (oldData.length) {
                console.debug('CLERK: Early enqueued events', oldData);
            }
        }

        this.push = ::this.push;
        /** @type {PageInfo|null} */
        this.pageInfo = null;
        /** @type {Dimensions|null} */
        this.cachedDimensions = null;

        const {
            endpointUrl,
            mirrorDimensions = [],
            eventMethod = null,
            activityMethod = null,
            pageviewMethod = null,
            fingerprintMethod = null,
            apeironSchema = null,
        } = config;

        this.mirrorDimensions = mirrorDimensions;

        if (DEBUG) {
            /** @type {ApeironSchema|null} */
            this.apeironSchema = apeironSchema;
        }

        const infoCallback =  () => this.pageInfo;

        this.error = errorHandlerFactory(endpointUrl, infoCallback);

        this.activityTransport = ::this.senderFactory(
            transportFactory(`${endpointUrl}/${activityMethod || 'activity'}`),
            this.error,
            infoCallback,
            false,
        );

        this.eventTransport = ::this.senderFactory(
            transportFactory(`${endpointUrl}/${eventMethod || 'events'}`),
            this.error,
            infoCallback,
            true,
        );

        this.fingerprintTransport = ::this.senderFactory(
            transportFactory(`${endpointUrl}/${fingerprintMethod || 'fingerprints'}`),
            this.error,
            infoCallback,
            true,
        );

        this.pageviewTransport = transportFactory(`${endpointUrl}/${pageviewMethod || 'pageviews'}`);

        this.queue = new Queue(::this.eventTransport, 150);
        this.metrics = new Metrics();

        this.initialize();

        win.addEventListener('beforeunload', () => {
            this.queue.flush();
            this.activityTransport([this.metrics.activity]);
        });

        oldData.forEach((event) => this.push(event));
    }

    /**
     * Main method to push arbitrary data to Clerk
     *
     * @param {object.<string, string>} obj Generic Clerk event
     * @param {boolean} [force=false] Should be event omit queue and push directly into transport?
     * @return {void}
     */
    push(obj, force=false) {
        let message = 'E003: Push error';
        try {
            const kind = obj._;
            delete obj._;
            switch(kind) {
                case 'pageview':
                    message = 'E031: Error sending pageview';
                    this.processPageview(obj);
                    break;

                case 'event':
                    message = 'E032: Error sending event';
                    this.pushEvent(obj, force);
                    break;

                case 'notify':
                    message = 'E033: Error sending notification';
                    obj['non_interaction'] = '1';
                    this.pushEvent(obj, force);
                    break;

                case 'fingerprint':
                    message = 'E034 Error sending fingerprint';
                    this.pushFingerprint(obj);
                    break;

                default:
                    message = 'E030: Invalid kind';
                    this.error(message, JSON.stringify(obj));
                    return;
            }
        } catch(e) {
            this.error(message, e);
            if (DEBUG) {
                console.error('CLERK: Failed to process', JSON.stringify(obj));
            }
        }
    }

    /**
     * Extend page info with dimensions from pageview
     *
     * @param {Dimensions} dimensions Dimensions from pageview
     * @return {void}
     */
    extendPageInfo(dimensions) {
        this.pageInfo.project = dimensions.project;
        this.pageInfo.commonDimensions = this.mirrorDimensions.reduce((acc, key) => {
            const value = dimensions[key];
            if (isNonEmpty(value)) {
                acc[key] = typeof value === 'object' ? JSON.stringify(value) : value.toString();
            }
            return acc;
        }, {});
    }

    /**
     * Process pageviews
     *
     * @param {Dimensions|null} [dimensions] Dimensions for pageview
     * @return {void}
     */
    processPageview(dimensions) {
        if(this.pageInfo) { // There are already stored message from iframe
            if (this.cachedDimensions) { // there are pageview in cache
                if (dimensions) {
                    this.error('E050: Dimensions conflict');
                    return;
                }
                if (DEBUG) {
                    console.debug('CLERK: Processing cached dimensions');
                }
                this.pushPageview(this.cachedDimensions);
                this.extendPageInfo(this.cachedDimensions);
                this.cachedDimensions = null;
                this.queue.start();
            } else if (dimensions) {
                if (this.queue.isStarted) {
                    if (DEBUG) {
                        console.debug('CLERK: Initiated new pageview');
                    }
                    this.activityTransport([this.metrics.activity]);
                    this.metrics.reset(true);
                    this.queue.stop();
                    this.pageInfo = null;
                    this.cachedDimensions = dimensions;
                    requestPageInfo();
                } else {
                    if (DEBUG) {
                        console.debug('CLERK: Processing received dimensions');
                    }
                    this.pushPageview(dimensions);
                    this.extendPageInfo(dimensions);
                    this.queue.start();
                }
            } else if (DEBUG) {
                console.debug('CLERK: Dimensions are not received yet, skipping page_info');
            }
        } else {
            if (this.cachedDimensions) {
                this.error('E051: Malformed pageview state');
                return;
            }
            if (!dimensions) {
                this.error('E052: Empty pageview state');
                return;
            }
            if (DEBUG) {
                console.debug('CLERK: Caching pageview for later processing');
            }
            this.cachedDimensions = dimensions;
        }
    }

    /**
     * Verify and push event to tracking system
     *
     * @param {ClerkEvent} ev Clerk event
     * @param {boolean} force Should be event omit queue and push directly into transport?
     * @return {void}
     */
    pushEvent(ev, force) {
        const event = cleanObject(ev);
        const {category, action} = event;

        if (!category) {
            this.error('E041: No category in event');
            return;
        }
        if (!action) {
            this.error('E041: No action in event');
            return;
        }
        if (DEBUG) {
            console.debug(`CLERK: ${force ? 'Forcing' : 'Queuing'} event ${category}:${action}`, event);
        }

        if (this.pageInfo && force) {
            this.eventTransport([event]);
        } else if (this.pageInfo && !force) {
            this.queue.pushWithFlush(event);
        } else {
            this.queue.push(event);
        }
    }

    /**
     * Verify and push fingerprint to tracking system
     *
     * @param {ClerkEvent} fp fingerprint
     * @return {void}
     */
    pushFingerprint(fp) {
        if (Object.keys(fp.fingerprint).length === 0) {
            this.error('E061: Empty fingerprint passed', fp.fingerprint);
            return;
        }
        if (!fp.category) {
            this.error('E062: No category in event');
            return;
        }
        if (!fp.action) {
            this.error('E063: No action in event');
            return;
        }
        const fingerprint = cleanObject(fp, ['fingerprint']);
        if (DEBUG) {
            console.debug(`CLERK: sending fingerprint`, fingerprint);
        }
        if (this.pageInfo) {
            this.fingerprintTransport([fingerprint]);
        }
    }

    /**
     * Construct and push pageview event to tracking system
     *
     * @param {Dimensions} dimensions user-provided dimensions for pageview
     * @return {void}
     * @see Dimensions
     */
    pushPageview(dimensions) {
        const features = {};
        try {
            Object.assign(features, detectFeatures());
        } catch (e) {
            this.error('E010: Error detecting browser features', e);
        }
        const { url=null , referrer=null } = dimensions.$ || {};
        delete dimensions.$;
        const payload = {
            url: url || getUrl(),
            referrer: referrer || doc.referrer,
            page_info: this.pageInfo,  // eslint-disable-line camelcase
            features,
            dimensions: Object.assign(
                { clerk_version: VERSION }, // eslint-disable-line camelcase
                cleanObject(dimensions)
            ),
        };

        if (DEBUG) {
            if (this.apeironSchema) {
                const errors = validateDimensions(this.apeironSchema, 'pageviews', payload.dimensions);
                if (errors.length > 0) {
                    this.error(
                        'E053: Cannot send pageview, dimension or ab_test code not in Apeiron schema. ' +
                        'Please update schema @ https://apeiron.stg.evo and reload this page.',
                        errors
                    );
                    return;
                }
            }

            console.debug('CLERK: Sending pageview', payload);
        }

        this.pageviewTransport(payload, this.error);
    }

    /**
     * Sender factory for different event types
     *
     * @param {Transport} transport Preferred transport to send payloads
     * @param {ErrorHandler} [err] Error callback
     * @param {InfoCallback} infoCallback
     * @param {boolean} addCommonDimensions
     * @return {Sender} function, ready to process clerk events
     * @see transportFactory Transport factory docstring
     */
    senderFactory(transport, err, infoCallback, addCommonDimensions) {
        /**
         * Send list of events to tracking endpoint
         */
        function sender(eventsList) {
            const pageInfo = infoCallback();
            const pageInfoPayload = {
                project: pageInfo.project,
                client_id: pageInfo.client_id, // eslint-disable-line camelcase
                page_id: pageInfo.page_id, // eslint-disable-line camelcase
                events: eventsList,
            };
            const commonDimensionsPayload = addCommonDimensions ? {
                common_dimensions: Object.assign({}, pageInfo.commonDimensions), // eslint-disable-line camelcase
            } : {};
            const payload = Object.assign({}, pageInfoPayload, commonDimensionsPayload);

            if (DEBUG) {
                if (this.apeironSchema) {
                    let mergedEvents = Object.assign({}, ...eventsList);
                    if (addCommonDimensions) {
                        Object.assign(mergedEvents, pageInfo.commonDimensions);
                    }
                    const errors = validateDimensions(this.apeironSchema, 'events', mergedEvents);
                    if (errors.length > 0) {
                        this.error(
                            'E054: Cannot send events, dimension or ab_test code not in Apeiron schema. ' +
                            'Please update schema @ https://apeiron.stg.evo and reload this page.',
                            errors
                        );
                        return false;
                    }
                }
                console.debug(`CLERK: Sending ${eventsList.length} events:`, payload);
            }

            return transport(payload, err);
        }

        return sender;
    }

    /**
     * Initializes clerk by attaching event listener and requesting page info from iframe
     * Literally it is main entry point to kick off entire tracking system
     */
    initialize() {
        win.addEventListener('message', ({data}) => {
            const {clerk} = data;
            if(clerk && clerk.pageInfo) {
                // Set page info from iframe
                if (this.pageInfo === null) {
                    if (DEBUG) {
                        console.debug('CLERK: Received pageInfo from iframe', clerk.pageInfo);
                    }
                    this.pageInfo = clerk.pageInfo;
                    sendInfo(this.pageInfo);
                } else {
                    if (DEBUG) {
                        console.debug('CLERK: Skip redundant pageInfo', clerk.pageInfo);
                    }
                }
                // Try to process pageview
                this.processPageview();
            }
        });

        win.addEventListener('message', ({data}) => {
            const {clerk} = data;
            if(clerk && clerk.request && clerk.request === 'getInfo' && this.pageInfo !== null) {
                if(DEBUG) {
                    console.debug('CLERK: Got getInfo request');
                }
                sendInfo(this.pageInfo);
            }
        });

        requestPageInfo();
    }
}
