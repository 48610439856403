import { console } from './tools';

/**
 * @typedef {object.<string, string>} ClerkEvent
 * @property {string} category Event category
 * @property {string} action Event action
 *
 * May contain arbitrary string fields
 **/


/**
 * @classdesc Event queue
 */
export class Queue {
    /**
     * Flushable event queue
     *
     * @param {function} callback
     * @param {number} timeout
     * @constructs
     */
    constructor(callback, timeout) {
        this.callback = callback;
        this.events = [];
        this.timer = null;
        this.isStarted = false;
        this.timeout = timeout;
    }

    /**
     * Push single event to queue
     *
     * @param {ClerkEvent} event
     */
    push(event) {
        this.events.push(event);
    }
    pushWithFlush(event) {
        this.push(event);
        if (this.isStarted && !this.timer) {
            this.timer = setTimeout(::this.flush, this.timeout);
        }
    }
    start() {
        this.isStarted = true;
        this.flush();
        if(DEBUG) {
            console.debug('CLERK: Started queue');
        }
    }
    stop() {
        this.isStarted = false;
        this.flush();
        if(DEBUG) {
            console.debug('CLERK: Stopped queue');
        }
    }
    flush() {
        if (!this.events.length) return;

        const chunk = this.events.splice(0, this.events.length);
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if(chunk.length) {
            this.callback(chunk);
        }
    }
}
