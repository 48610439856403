/* eslint-disable no-bitwise,no-mixed-operators,no-plusplus,no-continue */
/*
 * Base64 encoder
 * Code borrowed from:
 *      https://github.com/feross/buffer
 *      https://github.com/madmurphy/stringview.js
 */

function uint6ToB64(nUint6) {
    if (nUint6 < 26) {
        return nUint6 + 65;
    } else if (nUint6 < 52) {
        return nUint6 + 71;
    } else if (nUint6 < 62) {
        return nUint6 - 4;
    } else if (nUint6 === 62) {
        return 43;
    } else if (nUint6 === 63) {
        return 47;
    } else {
        return 65;
    }
}

function bytesToBase64(aBytes) {
    const eqLen = (3 - (aBytes.length % 3)) % 3;
    let sB64Enc = '';

    for (
        let nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0;
        nIdx < nLen;
        nIdx++
    ) {
        nMod3 = nIdx % 3;
        nUint24 |= aBytes[nIdx] << (16 >>> nMod3 & 24);
        if (nMod3 === 2 || aBytes.length - nIdx === 1) {
            sB64Enc += String.fromCharCode(
                uint6ToB64(nUint24 >>> 18 & 63),
                uint6ToB64(nUint24 >>> 12 & 63),
                uint6ToB64(nUint24 >>> 6 & 63),
                uint6ToB64(nUint24 & 63)
            );
            nUint24 = 0;
        }
    }

    if (eqLen === 0) {
        return sB64Enc;
    } else {
        return (
            sB64Enc.substring(0, sB64Enc.length - eqLen)
            + (eqLen === 1 ? '=' : '==')
        );
    }
}

function utf8ToBytes(string) {
    let codePoint;
    let length = string.length;
    let leadSurrogate = null;
    let bytes = [];
    let i = 0;
    for (; i < length; i++) {
        codePoint = string.charCodeAt(i);
        // is surrogate component
        if (codePoint > 0xD7FF && codePoint < 0xE000) {
            // last char was a lead
            if (leadSurrogate) {
                // 2 leads in a row
                if (codePoint < 0xDC00) {
                    bytes.push(0xEF, 0xBF, 0xBD);
                    leadSurrogate = codePoint;
                    continue;
                } else {
                    // valid surrogate pair
                    codePoint = (
                        leadSurrogate - 0xD800 << 10
                        | codePoint - 0xDC00 | 0x10000
                    );
                    leadSurrogate = null;
                }
            } else {
                // no lead yet
                if (codePoint > 0xDBFF) {
                    // unexpected trail
                    bytes.push(0xEF, 0xBF, 0xBD);
                    continue;
                } else if (i + 1 === length) {
                    // unpaired lead
                    bytes.push(0xEF, 0xBF, 0xBD);
                    continue;
                } else {
                    // valid lead
                    leadSurrogate = codePoint;
                    continue;
                }
            }
        } else if (leadSurrogate) {
            // valid bmp char, but last char was a lead
            bytes.push(0xEF, 0xBF, 0xBD);
            leadSurrogate = null;
        }
        // encode utf8
        if (codePoint < 0x80) {
            bytes.push(codePoint);
        } else if (codePoint < 0x800) {
            bytes.push(
                codePoint >> 0x6 | 0xC0,
                codePoint & 0x3F | 0x80
            );
        } else if (codePoint < 0x10000) {
            bytes.push(
                codePoint >> 0xC | 0xE0,
                codePoint >> 0x6 & 0x3F | 0x80,
                codePoint & 0x3F | 0x80
            );
        } else if (codePoint < 0x200000) {
            bytes.push(
                codePoint >> 0x12 | 0xF0,
                codePoint >> 0xC & 0x3F | 0x80,
                codePoint >> 0x6 & 0x3F | 0x80,
                codePoint & 0x3F | 0x80
            );
        } else {
            throw new Error('Invalid code point');
        }
    }
    return bytes;
}

export default (str) => bytesToBase64(utf8ToBytes(str));