const win = window;

let ClerkStats;
if(DEBUG) {
    ClerkStats = require('./test').DebugClerkStats;
} else {
    ClerkStats = require('./stats').ClerkStats;
}
win.clerkStats = new ClerkStats(win.CLERK_CONFIG, win.clerkStats);


