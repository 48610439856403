/**
 * @typedef {function} Transport
 * @param {object.<string, string>} payload
 * @param {ErrorHandler} [err] Error callback
 * @return {boolean} Success flag
 */

import base64Encoder from './base64';

/**
 * Send request via plain XHR
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest|MDN doc}
 * @param {string} endpoint
 * @param {object.<string, string>} payload
 * @param {ErrorHandler} [err] Error callback
 * @return {boolean} Success flag
 */
function xhr(endpoint, payload, err) {
    try {
        const xhr = new XMLHttpRequest();
        xhr.onerror = (e) => {
            const msg = 'E012: Error sending POST xhr';
            const details = `${e.type}:${e.name}:${e.target.status}:${e.target.statusText}`;
            return err(msg, details);
        };
        xhr.open('POST', endpoint, true);
        xhr.send(payload);
        return true;
    } catch (e) {
        if (err) err('E013: POST request could not be delivered', e);
        return false;
    }
}

/**
 * Send request via Beacon API
 *
 * @see {@link https://www.w3.org/TR/beacon/#sec-sendBeacon-method|W3C Docs}
 * @param {string} endpoint
 * @param {object.<string, string>} payload
 * @param {ErrorHandler} [err] Error callback
 * @return {boolean} Success flag
 */
function beacon(endpoint, payload, err) {
    try {
        if ((typeof navigator.sendBeacon) === 'function') {
            return navigator.sendBeacon(endpoint, payload);
        }
    } catch (e) {
        if (err) err('E018: Error sending beacon request', e);
        return false;
    }
}

/**
 * Transport function factory
 *
 * @param {string} url
 * @return {Transport} Sender function, tied to proper endpoint
 */
export default function transportFactory(url) {
    /**
     * Transport function
     */
    function transport(rawPayload, err) {
        const payload = base64Encoder(JSON.stringify(rawPayload));
        let beaconAccepted = true;
        if (beaconAccepted) {
            beaconAccepted = beacon(url, payload, err);
            if (!beaconAccepted) {
                // Downgrade to XHR and retry failed request
                return xhr(url, payload, err);
            }
            return true;
        } else {
            return xhr(url, payload, err);
        }
    }

    return transport;
}
