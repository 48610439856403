/* eslint-disable no-plusplus,no-bitwise */
const stub = () => {};
const win = window;
const consoleMock = {
    error: stub,
    debug: stub
};

const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const charNum = chars.length;
const cryptoApi = win.crypto || win.msCrypto;

export const safeLocalStorage = getSafeLocalStorage();
export const console = win.console || consoleMock;
export const toSeconds = (ms) => ms / 1000;
export const getUrl = () => win.location.href.split('#')[0];

// WARNING: There is some code, that relies on generated ID (including prefix)
// to be less than 36 bytes. We currently generate shorter IDs.
//
// For the record: the failure was in prom.ua with client ids (so page ids
// are probably safe). Still other places and projects may be influenced too
// (especially ones derived from prom)
//
export function genId(prefix) {
    let ts = +new Date();
    let buf;
    for(let i = 0; i < 7; ++i) {
        prefix += chars.charAt(ts % charNum);
        ts = (ts / charNum) | 0;
    }
    if(cryptoApi) {
        prefix += 'C';
        buf = new Uint32Array(6);
        cryptoApi.getRandomValues(buf);
    } else {
        prefix += 'R';
        const maxVal = Math.pow(charNum, 4);
        buf = [];
        for(let i = 0; i < 6; ++i) {
            // Getting about 24bits from every Math.random
            // See http://bit.ly/1N9zygT
            // It's safer than getting only 6 bits
            //
            // It's still bad, so we rely on 'crypto' being available
            // in most browsers
            buf.push((Math.random() * maxVal) | 0);
        }
    }
    // Beware length restrictions! (see comment above)
    for (let i = 0, r; i < 22; ++i) {
        if ((i & 3) === 0) {
            r = buf[i >> 2];
        }
        prefix += chars.charAt(r % charNum);
        r = (r / charNum) | 0;
    }
    return prefix;
}

export function identifyClient() {
    if(safeLocalStorage) {
        let cid = safeLocalStorage.getItem('clerk.client_id');
        if(cid === null) {
            cid = genId('c-');
            if(DEBUG) {
                console.debug(`CLERK IFRAME: No client id. Generated ${cid}`);
            }
            try {
                safeLocalStorage.setItem('clerk.client_id', cid);
            } catch(e) {
                console.error('E006: Exception when storing client id in localStorage', e);
            }
            let stored = safeLocalStorage.getItem('clerk.client_id');
            if(stored !== cid) {
                console.error('E007: Stored value differs from what we tried');
                if(stored) {
                    // We assume that this is a race condition,
                    // i.e. other page already stored it's client id
                    // so we reuse that id
                    return [stored, false];
                } else {
                    // Something wrong with storing ID, probably localStorage
                    // is disabled/failing/safari bug
                    return [cid, true];
                }
            } else {
                return [cid, true];
            }
        } else {
            if(DEBUG) {
                console.debug(`CLERK IFRAME: Client id: ${cid}`);
            }
            return [cid, false];
        }
    } else {
        // We assume that localStorage doesn't work, the user is new
        // This situation is similar to disabled cookies, anonymous mode and
        // so on, so probably it's okay
        //
        // In any case you can detect this kind of situation with `t-` prefix
        console.error('E005: The localStorage is not supported');
        return [genId('t-'), true];
    }
}

export function isNonEmpty(value) {
    return (value !== null) && (value !== undefined);
}

export function cleanObject(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (isNonEmpty(value)) {
            acc[key] = typeof value === 'object' ? JSON.stringify(value) : value.toString();
        }
        return acc;
    }, {});
}

export function getSafeLocalStorage() {
    /* eslint-disable no-storage/no-browser-storage */
    try {
        window.localStorage.setItem('___some_test___', 42);
        window.localStorage.removeItem('___some_test___');
        return window.localStorage;
    } catch (e) {
        return null;
    }
}
